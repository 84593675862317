import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { pick } from '../../lib/nodash';
import { Script } from 'gatsby';
import { CheckBox, Button, TextInput, Heading, Box, Text } from 'grommet';
import { FormNext, FormPrevious } from 'grommet-icons';
import loadable from '@loadable/component';

import { FormiumForm as FForm, defaultComponents } from '@formium/react';
const InputRadioButtonGroup = loadable(() =>
  import('../Inputs/InputRadioButtonGroup')
);
const InputTextArea = loadable(() => import('../Inputs/InputTextArea'));
import { formium } from '../../lib/formium';
import { track } from '../../lib/analytics';
import { FORMIUM_FORM_SUBMITTED } from '../../lib/analytics/segmentActions';

const inputPropTypes = {
  disabled: PropTypes.bool,
  type: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

const SubmitButton = ({ disabled, type, children, onClick }) => (
  <Button
    disabled={disabled}
    type={type}
    label={children}
    primary
    onClick={onClick}
    style={{ padding: '20px 22px' }}
  />
);
SubmitButton.propTypes = inputPropTypes;

const NextButton = ({ disabled, type, children, onClick }) => (
  <Button
    disabled={disabled}
    onClick={onClick}
    type={type}
    label={children}
    reverse
    icon={<FormNext />}
  />
);
NextButton.propTypes = inputPropTypes;

const PreviousButton = ({ disabled, type, children, onClick }) => (
  <Button
    disabled={disabled}
    onClick={onClick}
    type={type}
    label={children}
    icon={<FormPrevious />}
  />
);
PreviousButton.propTypes = inputPropTypes;

const FieldWrapper = ({ children }) => {
  return <Box margin={{ vertical: 'medium' }}>{children}</Box>;
};
FieldWrapper.propTypes = {
  children: PropTypes.node,
};

const FooterWrapper = ({ children }) => (
  <Box direction="row-responsive" gap="small">
    {children}
  </Box>
);

FooterWrapper.propTypes = {
  children: PropTypes.node,
};

const FormControl = ({ children, description, label, error, required }) => (
  <Box gap="small">
    {label && (
      <Text>
        {label}
        {required && <Text color="dark-4">*</Text>}
      </Text>
    )}
    {children}
    {description && <Text size="small">{description}</Text>}
    {error && <Text color="status-critical">{error}</Text>}
  </Box>
);

FormControl.propTypes = {
  children: PropTypes.node,
  description: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  required: PropTypes.bool,
};

const checkCaptcha = async () => {
  return new Promise((resolve, reject) => {
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute(process.env.GATSBY_RECAPTCHA_SITE_KEY, {
          action: 'submit',
        })
        .then(async function (token) {
          const res = await fetch(
            `${process.env.GATSBY_BACKDROP_API_URI}/recaptcha-verifications`,
            {
              method: 'POST',
              mode: 'cors',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                token: token,
              }),
            }
          );
          const { verified, error } = await res.json();
          if (!verified || error) {
            reject(error);
          } else {
            resolve();
          }
        });
    });
  });
};

const FormiumForm = ({ onComplete, data, showTitle = true, captcha }) => {
  const anonymousId = useSelector((state) => state.industry.anonymousId);
  const [formError, setFormError] = useState(null);
  const Header = ({ page }) => {
    return <Box>{showTitle && <Heading level={3}>{page.title}</Heading>}</Box>;
  };
  Header.propTypes = {
    form: PropTypes.object,
    page: PropTypes.object,
    pageIndex: PropTypes.number,
  };

  const customComponents = {
    ...defaultComponents,
    Checkbox: CheckBox,
    NextButton,
    PreviousButton,
    RadioGroup: InputRadioButtonGroup,
    SubmitButton,
    TextInput,
    Header,
    FieldWrapper,
    FooterWrapper,
    Textarea: InputTextArea,
    FormControl,
  };

  return (
    <>
      <FForm
        data={data}
        components={customComponents}
        showTitle={showTitle}
        onSubmit={async (values) => {
          try {
            if (captcha) {
              await checkCaptcha();
            }
            // Send form values to Formium
            const result = await formium.submitForm(data.slug, {
              ...values,
              anonymousId,
            });
            track(FORMIUM_FORM_SUBMITTED, {
              form: pick(['name', 'projectId', 'slug', 'id'], data),
            });
            if (onComplete) {
              onComplete(result, values);
            }
          } catch (e) {
            setFormError(
              'We cannot verify this submission. Please try again later.'
            );
          }
        }}
      />
      {formError && (
        <Box pad="medium">
          <Text color="status-error">{formError}</Text>
        </Box>
      )}
      {captcha && (
        <Script
          src={`https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_RECAPTCHA_SITE_KEY}`}
        />
      )}
    </>
  );
};

export default FormiumForm;

FormiumForm.propTypes = {
  captcha: PropTypes.bool,
  data: PropTypes.object.isRequired,
  slug: PropTypes.string.isRequired,
  onComplete: PropTypes.func,
  showTitle: PropTypes.bool,
};
